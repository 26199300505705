import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>What is this?</h1>
    <p>
      We are a group of families in the western suburbs of Minneapolis who have
      created a homeschool co-op to educate our middle-school age children. We
      have kicked off the 2020-2021 school year, but are still welcoming other
      families who may wish to join us.
    </p>
    <h1>How can I reach you?</h1>
    <p>
      We mostly collaborate in a{" "}
      <a href="https://www.facebook.com/groups/alcmn/">Facebook Group</a>, and
      this page is currently just a placeholder. To reach a person associated
      with this web site, please email{" "}
      <a href="mailto:&#109;&#105;&#107;&#101;+&#097;&#108;&#099;&#109;&#110;&#064;&#109;&#099;&#099;&#108;&#108;&#115;&#116;&#114;&#046;&#099;&#111;&#109;">
        Mike
      </a>
      .
    </p>
  </Layout>
)

export default IndexPage
